import React, { memo, Fragment } from "react";
//React-bootstrap
import {
  Accordion
} from "react-bootstrap";

//Componets
import SidebarMenu from "./sidebar-menu";

const VerticalNav = memo(() => {


  return (
    <Fragment>
      {/* <div id="sidebar-scrollbar"> */}
      <nav className="iq-sidebar-menu">
        <Accordion as="ul" className="iq-menu">
          <li className="iq-menu-title">
            <i className="ri-subtract-line"></i>
          </li>
          <SidebarMenu isTag="true" pathname="/" title="لوحة القيادة">
            <i className="ri-home-8-fill"></i>
          </SidebarMenu>
          <SidebarMenu isTag="true" pathname="#" title="بطاقة كليتي الإلكترونية">
            <i className="ri-bank-card-fill"></i>
          </SidebarMenu>
          <SidebarMenu
            isTag="true"
            pathname="/StepProgramme"
            title="برنامج خطوة"
          >
            <i className="ri-calendar-todo-line"></i>
          </SidebarMenu>
          <SidebarMenu
            isTag="true"
            pathname="/Promo"
            title="تخفيضات"
          >
            <i className="ri-percent-line"></i>
          </SidebarMenu>

          {/* Email menu Start */}
        </Accordion>
      </nav>
      <div className="p-3"></div>
      {/* </div> */}
    </Fragment>
  );
});

VerticalNav.displayName = "VerticalNav";
export default VerticalNav;
