import Promo from "../views/modules/Promo/";
import StepProgramme from "../views/modules/stepProgramme/";

export const AppRouter = [
    {
        path: "Promo",
        element: <Promo />
    },
    {
        path: "StepProgramme",
        element: <StepProgramme />
    }
]