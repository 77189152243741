import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

// Images
import user1 from "../../../assets/images/brandLogo/brand.png";
import user2 from "../../../assets/images/user/13.jpg";
import user3 from "../../../assets/images/user/14.jpg";
import user4 from "../../../assets/images/user/15.jpg";
import user5 from "../../../assets/images/user/16.jpg";
import user6 from "../../../assets/images/user/17.jpg";
import user7 from "../../../assets/images/user/18.jpg";

// Link
import { Link } from "react-router-dom";

const AllDoctors = () => {
    return (
        <Fragment>
            <Row>
                <Col sm="12">
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">لائحة العروض</h4>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="6" md="3">
                    <div className="iq-card">
                        <div className="iq-card-body text-center">
                            <div className="doc-profile">
                                <img
                                    className="rounded-circle img-fluid avatar-80"
                                    src={user1}
                                    alt="profile"
                                />
                            </div>
                            <div className="iq-doc-info mt-3">
                                <h4> Nike</h4>

                                <Link to="www.nike.com" target="_blank">www.brand.com</Link>
                            </div>
                            <div className="iq-doc-description mt-2">

                            </div>
                            <div className="iq-doc-social-info mt-3 mb-3">
                                <ul className="m-0 p-0 list-inline" dir="ltr">
                                    <li>
                                        <Link to="#">
                                            20
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            %
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="6" md="3">
                    <div className="iq-card">
                        <div className="iq-card-body text-center">
                            <div className="doc-profile">
                                <img
                                    className="rounded-circle img-fluid avatar-80"
                                    src={user1}
                                    alt="profile"
                                />
                            </div>
                            <div className="iq-doc-info mt-3">
                                <h4> Nike</h4>

                                <Link to="www.nike.com" target="_blank">www.brand.com</Link>
                            </div>
                            <div className="iq-doc-description mt-2">

                            </div>
                            <div className="iq-doc-social-info mt-3 mb-3">
                                <ul className="m-0 p-0 list-inline" dir="ltr">
                                    <li>
                                        <Link to="#">
                                            27
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            %
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="6" md="3">
                    <div className="iq-card">
                        <div className="iq-card-body text-center">
                            <div className="doc-profile">
                                <img
                                    className="rounded-circle img-fluid avatar-80"
                                    src={user1}
                                    alt="profile"
                                />
                            </div>
                            <div className="iq-doc-info mt-3">
                                <h4> Nike</h4>

                                <Link to="www.nike.com" target="_blank">www.brand.com</Link>
                            </div>
                            <div className="iq-doc-description mt-2">

                            </div>
                            <div className="iq-doc-social-info mt-3 mb-3">
                                <ul className="m-0 p-0 list-inline" dir="ltr">
                                    <li>
                                        <Link to="#">
                                            9
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            %
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="6" md="3">
                    <div className="iq-card">
                        <div className="iq-card-body text-center">
                            <div className="doc-profile">
                                <img
                                    className="rounded-circle img-fluid avatar-80"
                                    src={user1}
                                    alt="profile"
                                />
                            </div>
                            <div className="iq-doc-info mt-3">
                                <h4> Nike</h4>

                                <Link to="www.nike.com" target="_blank">www.brand.com</Link>
                            </div>
                            <div className="iq-doc-description mt-2">

                            </div>
                            <div className="iq-doc-social-info mt-3 mb-3">
                                <ul className="m-0 p-0 list-inline" dir="ltr">
                                    <li>
                                        <Link to="#">
                                            31
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            %
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="6" md="3">
                    <div className="iq-card">
                        <div className="iq-card-body text-center">
                            <div className="doc-profile">
                                <img
                                    className="rounded-circle img-fluid avatar-80"
                                    src={user1}
                                    alt="profile"
                                />
                            </div>
                            <div className="iq-doc-info mt-3">
                                <h4> Nike</h4>

                                <Link to="www.nike.com" target="_blank">www.brand.com</Link>
                            </div>
                            <div className="iq-doc-description mt-2">

                            </div>
                            <div className="iq-doc-social-info mt-3 mb-3">
                                <ul className="m-0 p-0 list-inline" dir="ltr">
                                    <li>
                                        <Link to="#">
                                            30
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            %
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="6" md="3">
                    <div className="iq-card">
                        <div className="iq-card-body text-center">
                            <div className="doc-profile">
                                <img
                                    className="rounded-circle img-fluid avatar-80"
                                    src={user1}
                                    alt="profile"
                                />
                            </div>
                            <div className="iq-doc-info mt-3">
                                <h4> Nike</h4>

                                <Link to="www.nike.com" target="_blank">www.brand.com</Link>
                            </div>
                            <div className="iq-doc-description mt-2">

                            </div>
                            <div className="iq-doc-social-info mt-3 mb-3">
                                <ul className="m-0 p-0 list-inline" dir="ltr">
                                    <li>
                                        <Link to="#">
                                            3
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            %
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="6" md="3">
                    <div className="iq-card">
                        <div className="iq-card-body text-center">
                            <div className="doc-profile">
                                <img
                                    className="rounded-circle img-fluid avatar-80"
                                    src={user1}
                                    alt="profile"
                                />
                            </div>
                            <div className="iq-doc-info mt-3">
                                <h4> Nike</h4>

                                <Link to="www.nike.com" target="_blank">www.brand.com</Link>
                            </div>
                            <div className="iq-doc-description mt-2">

                            </div>
                            <div className="iq-doc-social-info mt-3 mb-3">
                                <ul className="m-0 p-0 list-inline" dir="ltr">
                                    <li>
                                        <Link to="#">
                                            17
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            %
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="6" md="3">
                    <div className="iq-card">
                        <div className="iq-card-body text-center">
                            <div className="doc-profile">
                                <img
                                    className="rounded-circle img-fluid avatar-80"
                                    src={user1}
                                    alt="profile"
                                />
                            </div>
                            <div className="iq-doc-info mt-3">
                                <h4> Nike</h4>

                                <Link to="www.nike.com" target="_blank">www.brand.com</Link>
                            </div>
                            <div className="iq-doc-description mt-2">

                            </div>
                            <div className="iq-doc-social-info mt-3 mb-3">
                                <ul className="m-0 p-0 list-inline" dir="ltr">
                                    <li>
                                        <Link to="#">
                                            13
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            %
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col sm="6" md="3">
                    <div className="iq-card">
                        <div className="iq-card-body text-center">
                            <div className="doc-profile">
                                <img
                                    className="rounded-circle img-fluid avatar-80"
                                    src={user1}
                                    alt="profile"
                                />
                            </div>
                            <div className="iq-doc-info mt-3">
                                <h4> Nike</h4>

                                <Link to="www.nike.com" target="_blank">www.brand.com</Link>
                            </div>
                            <div className="iq-doc-description mt-2">

                            </div>
                            <div className="iq-doc-social-info mt-3 mb-3">
                                <ul className="m-0 p-0 list-inline" dir="ltr">
                                    <li>
                                        <Link to="#">
                                            22
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            %
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="6" md="3">
                    <div className="iq-card">
                        <div className="iq-card-body text-center">
                            <div className="doc-profile">
                                <img
                                    className="rounded-circle img-fluid avatar-80"
                                    src={user1}
                                    alt="profile"
                                />
                            </div>
                            <div className="iq-doc-info mt-3">
                                <h4> Nike</h4>

                                <Link to="www.nike.com" target="_blank">www.brand.com</Link>
                            </div>
                            <div className="iq-doc-description mt-2">

                            </div>
                            <div className="iq-doc-social-info mt-3 mb-3">
                                <ul className="m-0 p-0 list-inline" dir="ltr">
                                    <li>
                                        <Link to="#">
                                            5
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            %
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="6" md="3">
                    <div className="iq-card">
                        <div className="iq-card-body text-center">
                            <div className="doc-profile">
                                <img
                                    className="rounded-circle img-fluid avatar-80"
                                    src={user1}
                                    alt="profile"
                                />
                            </div>
                            <div className="iq-doc-info mt-3">
                                <h4> Nike</h4>

                                <Link to="www.nike.com" target="_blank">www.brand.com</Link>
                            </div>
                            <div className="iq-doc-description mt-2">

                            </div>
                            <div className="iq-doc-social-info mt-3 mb-3">
                                <ul className="m-0 p-0 list-inline" dir="ltr">
                                    <li>
                                        <Link to="#">
                                            15
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            %
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="6" md="3">
                    <div className="iq-card">
                        <div className="iq-card-body text-center">
                            <div className="doc-profile">
                                <img
                                    className="rounded-circle img-fluid avatar-80"
                                    src={user1}
                                    alt="profile"
                                />
                            </div>
                            <div className="iq-doc-info mt-3">
                                <h4> Nike</h4>

                                <Link to="www.nike.com" target="_blank">www.brand.com</Link>
                            </div>
                            <div className="iq-doc-description mt-2">

                            </div>
                            <div className="iq-doc-social-info mt-3 mb-3">
                                <ul className="m-0 p-0 list-inline" dir="ltr">
                                    <li>
                                        <Link to="#">
                                            40
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            %
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};

export default AllDoctors;
