import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";

// Img
import training from "../../../assets/images/step/training.jpg";
import mo9awalati from "../../../assets/images/step/mo9awalati.jpg";
import tamwil from "../../../assets/images/step/tamwil.jpg";
import suivie from "../../../assets/images/step/suivie.jpg";

import * as am4core from "@amcharts/amcharts4/core";
// eslint-disable-next-line camelcase
import "../../../assets/css/step.css"
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);


const Index = () => {
    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Row>
                            <Col md="6" lg="3">
                                <img src={training} width={'100%'} alt="" className="khotwaCard" />
                            </Col>
                            <Col md="6" lg="3">
                                <img src={mo9awalati} width={'100%'} alt="" className="khotwaCard" />
                            </Col>
                            <Col md="6" lg="3">
                                <img src={tamwil} width={'100%'} alt="" className="khotwaCard" />
                            </Col>
                            <Col md="6" lg="3">
                                <img src={suivie} width={'100%'} alt="" className="khotwaCard" />
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>
        </Fragment>
    );
};

export default Index;
